var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.productGridV3 = {
    attach: function (context) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var $products = $('.js-product-grid-item', context);
      var $sortByNew = $('.js-mpp-sort-option--new', context);
      var $sortByBestSeller = $('.js-mpp-sort-option--bestseller', context);
      var newestFlag = 0;
      var bestSellerFlag = 0;

      // For any products set to OOS that are also in the 'ticker', hide them
      // Per CX-219, the brand says these are 'limited life' and need to not be displayed
      $(document).on('ticker_product_oos', '.js-product', function (event, passedProdId) {
        var updatePageSliders = false;
        // target specific grid item, we don't need to manipulate other elements on the page
        var $prodSlctr = $("[data-product-id='" + passedProdId + "']", this).closest('.js-product-grid-item');
        var updated = false;

        if ($prodSlctr && $prodSlctr.length > 0) {
          $prodSlctr.once('ticker-oos').each(function () {
            var $product = $(this);
            var prodObj = prodcat.data.getProduct(passedProdId);
            var notSOSkus = [];
            var numberOfOccurrenceList = $.grep(prodObj.skus, function (sku) {
              // Checking Sold Out and added in ticker.
              var isSoInTicker = sku.INVENTORY_STATUS === 7 && sku.highVelocityQty === 0;

              if (!isSoInTicker) {
                notSOSkus.push(sku.SKU_ID);
              }

              return isSoInTicker;
            }).length;
            var allSkuSO = numberOfOccurrenceList === prodObj.skus.length;

            $product.data('available-skus', notSOSkus.join(','));
            if (allSkuSO) {
              var isSpp = $product.hasClass('js-product-full');

              if (isSpp) {
                var localePrefix = Drupal.settings.pathPrefix || '';

                window.location.href = '/' + localePrefix;

                return false;
              }
              // mixitup grid breaks if items are hidden by class, since it uses display toggle
              // for filters, need to remove the item from the DOM
              $product.trigger('products.remove-ticker-flagged', passedProdId);
              $product.remove();
              updated = true;

              var $slickItem = $product.parent('.slick-slide');

              if ($slickItem.length > 0) {
                $slickItem.closest('.slick-slider.slick-initialized').data('updated', 1).attr('data-updated', 1);
                $slickItem.remove();
                updatePageSliders = true;
              }
            }
          });
          if (updatePageSliders) {
            // need to update sliders from the updated items, we filter based on the custom attr we add if slider
            // requires update
            $('.slick-slider')
              .filter('[data-updated="1"]')
              .each(function () {
                var $slider = $(this);

                $slider.data('updated', 0).attr('data-updated', 0);
                $slider.find('.slick-cloned').remove();
                $slider.slick('reinit');
              });
          }
          if (updated) {
            $grids.each(function () {
              var $grid = $(this);

              if ($grid.hasClass('mixitup-loaded')) {
                $grid.trigger('grid.reflow');
              } else {
                $grid.trigger('mixitup.initialize');
              }
            });
          }
        }
      });

      $products.each(function () {
        // Add sort attributes based on MISC_FLAG_TEXT string
        var $flagElement = $(this).find('.js-product-badge-text');
        var flagLabel = $flagElement.text().toLowerCase().trim();

        if (flagLabel === 'new' || flagLabel === 'new arrival' || flagLabel === 'new arrivals') {
          $(this).attr('data-newest-sort', '1');
          newestFlag = 1;
          // unhide sort option
          $sortByNew.removeClass('hidden');
        } else if (
          flagLabel === 'best seller' ||
          flagLabel === 'best sellers' ||
          flagLabel === 'bestseller' ||
          flagLabel === 'bestsellers'
        ) {
          $(this).attr('data-bestseller-sort', '1');
          // unhide sort option
          $sortByBestSeller.removeClass('hidden');
          bestSellerFlag = 1;
        }
      });

      if (!newestFlag) {
        $sortByNew.remove();
      } else if (!bestSellerFlag) {
        $sortByBestSeller.remove();
      }

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $carousels.each(function () {
        var $carousel = $(this);
        var arrowsDiv = $carousel.parent().find('.carousel-controls');
        var dotsDiv = $carousel.parent().find('.carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $carousel.data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $carousel.slick(settings);
      });

      // Filter event:
      $grids.on('productGrid.filter', function (event, prodsToShow, sort) {
        var $items = $('.js-product-grid-item', this);
        var $filteredItems = $items.filter(function () {
          return _.includes(prodsToShow, $(this).data('product-id'));
        });
        var $grid = $(this);
        var $container = $items.first().parent();

        // First, undo any previous sorting we may have done:
        _resetFilterSort($grid);

        // (Optionally) Sort:
        if (sort) {
          // Put a placeholder before the items we're going to sort so we can
          // un-sort them later (_resetFilterSort).
          $filteredItems.before(sortPlaceholder);
          // Reverse the array because we're prepending. Appending also works,
          // but this way we can target :first-child in css to get the primary
          // result in regimens.
          _.each(_.clone(prodsToShow).reverse(), function (id) {
            var $item = $filteredItems.filter('[data-product-id="' + id + '"]');

            $item.prependTo($container);
          });
        }

        // Filter:
        $items.addClass('hidden');
        $filteredItems.removeClass('hidden');

        $(this).trigger('grid.reflow');
      });

      // Reset filter event:
      $grids.on('productGrid.showAll', function () {
        var $grid = $(this);

        _resetFilterSort($grid);
        $('.js-product-grid-item', this).removeClass('hidden');
        $grid.trigger('grid.reflow');
      });
    }
  };

  function _resetFilterSort($grid) {
    var $items = $('.js-product-grid-item', $grid);

    $('.js-product-grid-sort-placeholder', $grid).each(function () {
      var $ph = $(this);
      var id = $ph.data('placeholder-product-id');
      var $item = $items.filter('[data-product-id="' + id + '"]');

      $ph.after($item).remove();
    });
  }
})(jQuery);
